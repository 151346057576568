import { Component, Watch } from 'vue-property-decorator';
import OsTable, { OsTableColumn, OsTableOption } from '@/components/os-table/os-table';
import { OsQueryPlan } from '@/components';
import { OsQueryItemOption } from '@/components/os-table-query/os-table-query';
import { LogisticsList, LogisticsListQuery } from '@/resource/model';
import { messageError, dateFormat, downloadFileByBlob, translation, debounce } from '@/utils';
import { logisticsService } from '@/api';
import { mixins } from 'vue-class-component';
import { PagingMixin } from '@/mixins/paging';
import { DeliveryModeEnum, LogisticsOrderStatusEnum, LogisticsStatusEnum, PageQueryPlanEnum } from '@/resource/enum';
import { CustomColumnMixin } from '@/mixins/custom-column';
import { OperationOption } from '@/components/os-table-operation/os-table-operation';
import { QueryPlanOperationOption } from '@/components/os-query-plan/os-query-plan';
import { NormalSelectOptions } from '@/resource/model/common';
@Component({
  name: 'Logistics',
  components: {}
})
export default class Logistics extends mixins(PagingMixin, CustomColumnMixin) {
  public tableOption: OsTableOption<LogisticsList> = {
    loading: false,
    data: [],
    fit: true,
    size: 'small'
  };

  public defaultColumnOptions: Array<OsTableColumn<LogisticsList>> = [
    {
      type: 'selection',
      prop: 'id',
      label: '',
      reserveSelection: true,
      fixed: true
    },
    {
      prop: 'code',
      label: 'logistics.code',
      minWidth: '200px',
      showOverflowTooltip: true,
      fixed: true
    },
    {
      prop: 'deliveryNoticeCode',
      label: 'logistics.relevanceDeliveryBill',
      minWidth: '180px',
      showOverflowTooltip: true
    },
    {
      prop: 'customerName',
      label: 'logistics.relevanceCustomer',
      minWidth: '150px',
      showOverflowTooltip: true
    },
    {
      prop: 'projectName',
      label: 'logistics.relevanceProject',
      minWidth: '150px',
      showOverflowTooltip: true
    },
    {
      prop: 'requiredArriveTime',
      label: 'logistics.requiredArriveTime',
      minWidth: '150px',
      showOverflowTooltip: true
    },
    {
      prop: 'arrivalTime',
      label: 'logistics.arrivalTime',
      minWidth: '150px',
      showOverflowTooltip: true
    },
    {
      prop: 'childCount',
      label: 'logistics.childCount',
      showOverflowTooltip: true,
      minWidth: '100px'
    },
    {
      prop: 'sendMethod',
      label: 'logistics.logisticsInfo',
      showOverflowTooltip: true,
      minWidth: '200px'
    },
    {
      prop: 'weight',
      label: 'logistics.weight',
      minWidth: '100px',
      showOverflowTooltip: true
    },
    {
      prop: 'price',
      label: 'logistics.price',
      minWidth: '150px',
      showOverflowTooltip: true
    },
    {
      prop: 'status',
      label: 'logistics.status',
      showOverflowTooltip: true,
      minWidth: '150px'
    },
    {
      prop: 'logisticsStatus',
      label: 'logistics.logisticsStatus',
      showOverflowTooltip: true,
      minWidth: '150px'
    },
    {
      prop: 'newLogisticsTrack',
      label: 'logistics.newLogisticsTrack',
      showOverflowTooltip: true,
      minWidth: '180px'
    },
    {
      prop: 'receiver',
      label: 'logistics.receiverInfo',
      showOverflowTooltip: true,
      minWidth: '200px'
    },
    {
      prop: 'sender',
      label: 'logistics.senderInfo',
      showOverflowTooltip: true,
      minWidth: '200px'
    },
    {
      prop: 'subscribeStatus',
      label: 'logistics.subscribeStatus',
      showOverflowTooltip: true,
      minWidth: '150px'
    },
    {
      prop: 'remark',
      label: 'common.remark',
      showOverflowTooltip: true,
      minWidth: '180px'
    },
    {
      prop: 'createUserName',
      label: 'common.createUser',
      showOverflowTooltip: true,
      minWidth: '180px'
    },
    {
      prop: 'createTime',
      label: 'common.createTime',
      showOverflowTooltip: true,
      minWidth: '180px',
      formatter: (row: object): string => {
        return dateFormat((row as LogisticsList).createTime);
      }
    }
  ];

  public queryItemsOption: Array<
    OsQueryItemOption<
      LogisticsListQuery & {
        deliveryTime: Array<string>;
        receivingTime: Array<string>;
        requiredArriveTime: Array<string>;
        statusList: Array<number>;
        canTimeout: number;
        toBeTimeout: number;
      }
    >
  > = [
    {
      type: 'Input',
      field: 'code',
      label: 'logistics.code',
      option: {
        placeholder: 'logistics.inputCode'
      }
    },
    {
      type: 'Input',
      field: 'logisticsNo',
      label: 'logistics.logisticsNo',
      option: {
        placeholder: 'logistics.inputLogisticsNo'
      }
    },
    {
      type: 'Select',
      field: 'statusList',
      label: 'logistics.status',
      option: {
        placeholder: 'logistics.seelctOrderStatus',
        multiple: true
      },
      optionData: this.getLogisticsOrderStatus
    },
    {
      type: 'Select',
      field: 'sendMethod',
      label: 'logistics.deliveryMode',
      option: {
        placeholder: 'logistics.selectDeliveryMode'
      },
      optionData: logisticsService.getDeliveryMode
    },
    {
      type: 'Select',
      field: 'customerId',
      label: 'logistics.relevanceCustomer',
      option: {
        placeholder: 'logistics.selectCustomer',
        filterable: true
      },
      optionData: []
    },

    {
      type: 'Select',
      field: 'logisticsStatusList',
      label: 'logistics.logisticsStatus',
      option: {
        placeholder: 'logistics.seelctStatus',
        multiple: true
      },
      optionData: this.getLogisticsStatus
    },
    {
      type: 'Select',
      field: 'expressCom',
      label: 'logistics.expressCompany',
      option: {
        placeholder: 'logistics.selectExpressCompany'
      },
      optionData: []
    },
    {
      type: 'DateRangePicker',
      field: 'deliveryTime',
      label: 'logistics.deliveryTime',
      option: {
        rangeSeparator: '~'
      }
    },
    {
      type: 'DateRangePicker',
      field: 'receivingTime',
      label: 'logistics.receivingTime',
      option: {
        rangeSeparator: '~'
      }
    },
    {
      type: 'Input',
      field: 'shopName',
      label: 'customerShop.name',
      option: {
        placeholder: 'customerShop.inputName'
      }
    },
    {
      type: 'Input',
      field: 'receivingCity',
      label: 'logistics.receivingCity',
      option: {
        placeholder: 'logistics.inputReceivingCity'
      }
    },

    {
      type: 'DateRangePicker',
      field: 'requiredArriveTime',
      label: 'logistics.requiredArriveTime',
      option: {
        rangeSeparator: '~'
      }
    },
    {
      type: 'Select',
      field: 'canTimeout',
      label: 'projectProduct.isOverdue',
      option: {
        placeholder: 'common.select'
      },
      optionData: (): NormalSelectOptions => {
        return [
          {
            label: translation('common.yes'),
            value: 1
          }
        ];
      }
    },
    {
      type: 'Select',
      field: 'toBeTimeout',
      label: 'projectProduct.toBeTimeout',
      option: {
        placeholder: 'common.select'
      },
      optionData: (): NormalSelectOptions => {
        return [
          {
            label: translation('common.yes'),
            value: 1
          }
        ];
      }
    }
  ];

  /**
   * 页面标识
   */
  public code: number = PageQueryPlanEnum.logistics;
  /**
   * 查询方案编辑按钮
   */
  public queryPlanEndOption: Array<QueryPlanOperationOption> = [
    {
      id: 0,
      type: 'primary',
      slot: 'end',
      label: 'button.save',
      permissionCode: 'system:query:template:save',
      handleClick: (): void => {
        (this.$refs.OsQueryPlan as OsQueryPlan).dialogOpened();
      }
    },
    {
      id: 1,
      type: 'text',
      slot: 'end',
      label: 'button.edit',
      permissionCode: 'system:query:template:delete',
      handleClick: (): void => {
        (this.$refs.OsQueryPlan as OsQueryPlan).editDialogOpened();
      }
    }
  ];

  public operationOptions: Array<OperationOption> = [
    {
      slot: 'start',
      label: 'logistics.export',
      operationType: 'export',
      icon: 'el-icon-download',
      permissionCode: 'logistics:order:export',
      plain: true,
      handleClick: this.export
    }
  ];

  public customers: Array<{ customerId: number; customerName: string }> = [];

  public selectedRows: Array<LogisticsList> = [];

  private queryForm: Partial<LogisticsListQuery> = {
    customerId: undefined,
    code: '',
    receivingTime: [],
    deliveryTime: [],
    statusList: [],
    logisticsStatusList: [],
    shopName: '',
    expressCom: '',
    logisticsNo: '',
    receivingCity: '',
    requiredArriveTime: [],
    sendMethod: undefined,
    canTimeout: undefined,
    toBeTimeout: undefined,
    sendStartTime: undefined,
    sendEndTime: undefined,
    receivingStartTime: undefined,
    receivingEndTime: undefined,
    requiredArriveTimeStart: undefined,
    requiredArriveTimeEnd: undefined
  };

  private defaultQueryForm: Partial<LogisticsListQuery> = {
    code: '',
    customerId: undefined,
    deliveryTime: [],
    expressCom: '',
    logisticsNo: '',
    logisticsStatusList: [],
    receivingCity: '',
    receivingTime: [],
    requiredArriveTime: [],
    sendMethod: undefined,
    shopName: '',
    statusList: [],
    canTimeout: undefined,
    toBeTimeout: undefined,
    sendStartTime: undefined,
    sendEndTime: undefined,
    receivingStartTime: undefined,
    receivingEndTime: undefined,
    requiredArriveTimeStart: undefined,
    requiredArriveTimeEnd: undefined
  };

  public activated(): void {
    if (this.$route.query.logisticsNo || this.$route.query.projectCode) {
      Object.assign(this.defaultQueryForm, {
        code: (this.$route.query.logisticsNo as string) || (this.$route.query.projectCode as string) || '',
        shopName: (this.$route.query.shopName as string) ?? ''
      });
      Object.assign(this.queryForm, this.defaultQueryForm);
    }
    this.queryClick();
  }

  public created(): void {
    this.initColumns(this.defaultColumnOptions, 'logistics');
    this.getCustomers();
    this.queryClick();
    this.getExpressCompanys();
  }

  public reloadData(): void {
    this.paging.currentPage = 1;
    // 重置查询方案
    (this.$refs.OsQueryPlan as OsQueryPlan).id = 0;
    this.clearSelection();
    this.loadData();
  }

  public pagingData(): void {
    this.loadData();
  }

  @debounce()
  public queryClick(): void {
    if (this.$route.query.queryPlanName) {
      this.paging.currentPage = 1;
      (this.$refs.OsQueryPlan as OsQueryPlan).selectQueryPlan(this.$route.query.queryPlanName as string);
      return;
    }
    this.loadData();
  }

  /**
   * 执行查询方案
   * @param json
   */
  public queryList(json: string): void {
    this.paging.currentPage = 1;
    Object.assign(this.queryForm, this.defaultQueryForm);
    Object.assign(this.queryForm, JSON.parse(json));
    this.loadData();
  }

  public linkToDetails(logistics: LogisticsList): void {
    this.$router.push({
      path: 'logistics-details',
      query: {
        id: logistics.id.toString()
      }
    });
  }

  public getStatusClass(logistics: LogisticsList): string {
    switch (logistics.status) {
      case LogisticsOrderStatusEnum.new:
        return 'info-dot';
      case LogisticsOrderStatusEnum.receivedGoods:
        return 'primary-dot';
      case LogisticsOrderStatusEnum.closed:
        return 'success-dot';
      default:
        return 'danger-dot';
    }
  }

  public getStatusName(logistics: LogisticsList): string {
    if (!LogisticsOrderStatusEnum[logistics.status]) {
      return 'common.unKnownStatus';
    }
    return `logisticsOrderStatus.${LogisticsOrderStatusEnum[logistics.status]}`;
  }
  public getlogisticsStatusClass(logistics: LogisticsList): string {
    switch (logistics.logisticsStatus) {
      case LogisticsStatusEnum.new:
        return 'info-dot';
      case LogisticsStatusEnum.signBack:
        return 'primary-dot';
      case LogisticsStatusEnum.customsClearance:
        return 'success-dot';
      default:
        return 'danger-dot';
    }
  }
  public getlogisticsStatusName(logistics: LogisticsList): string {
    if (!LogisticsStatusEnum[logistics.logisticsStatus]) {
      return 'common.unKnownStatus';
    }
    return `logisticsStatus.${LogisticsStatusEnum[logistics.logisticsStatus]}`;
  }

  public getSubscribeStatusClass(subscribeStatus: number): string {
    return subscribeStatus === 1 ? 'info-dot' : 'success-dot';
  }

  public getSubscribeStatusName(subscribeStatus: number): string {
    return subscribeStatus === 1 ? 'logistics.unSubscribe' : 'logistics.subscribeSuccess';
  }

  public getDeliveryMode(mode: DeliveryModeEnum): string {
    return `deliveryMode.${DeliveryModeEnum[mode]}`;
  }

  public getReceiverAdress(logistics: LogisticsList): string {
    return `${logistics.receivingProvince ?? ''}${logistics.receivingCity ?? ''} ${logistics.receivingDistrict ??
      ''}${logistics.receivingAddress ?? ''}`;
  }

  public getSenderAdress(logistics: LogisticsList): string {
    return `${logistics.sendProvince ?? ''}${logistics.sendCity ?? ''}${logistics.sendDistrict ??
      ''}${logistics.senderAddress ?? ''}`;
  }

  public handleSelectionChange(selectedData: Array<LogisticsList>): void {
    this.selectedRows = selectedData;
  }

  private loadData(): void {
    this.tableOption.loading = true;
    logisticsService
      .getList(this.queryForm, this.paging)
      .then(res => {
        this.tableOption.data = res.data;
        this.totalData = res.total;
      })
      .catch(error => {
        messageError(error);
      })
      .finally(() => {
        this.tableOption.loading = false;
      });
  }

  private getCustomers(): void {
    logisticsService
      .getProjectCustomer()
      .then(res => {
        this.customers = res;
        const customerQuery = this.queryItemsOption.find(x => x.field === 'customerId');
        customerQuery!.optionData = res.map(x => {
          return { label: x.customerName, value: x.customerId };
        });
      })
      .catch(error => {
        messageError(error);
      });
  }

  @Watch('queryForm.deliveryTime')
  private handleRequireArriveTimeChanged(value: Array<string>): void {
    if (!value || value.length === 0) {
      this.queryForm.sendStartTime = undefined;
      this.queryForm.sendEndTime = undefined;
      return;
    }
    if (value && value.length === 2) {
      this.queryForm.sendStartTime = dateFormat(value[0]);
      this.queryForm.sendEndTime = dateFormat(value[1], 'YYYY-MM-DD') + ' 23:59:59';
    }
  }

  @Watch('queryForm.receivingTime')
  private handleRequireDeliveryTimeChanged(value: Array<string>): void {
    if (!value || value.length === 0) {
      this.queryForm.receivingStartTime = undefined;
      this.queryForm.receivingEndTime = undefined;
      return;
    }
    if (value && value.length === 2) {
      this.queryForm.receivingStartTime = dateFormat(value[0]);
      this.queryForm.receivingEndTime = dateFormat(value[1], 'YYYY-MM-DD') + ' 23:59:59';
    }
  }
  @Watch('queryForm.requiredArriveTime')
  private handlerequiredArriveTimeChanged(value: Array<string>): void {
    if (!value || value.length === 0) {
      this.queryForm.requiredArriveTimeStart = undefined;
      this.queryForm.requiredArriveTimeEnd = undefined;
      return;
    }
    if (value && value.length === 2) {
      this.queryForm.requiredArriveTimeStart = dateFormat(value[0]);
      this.queryForm.requiredArriveTimeEnd = dateFormat(value[1], 'YYYY-MM-DD') + ' 23:59:59';
    }
  }

  private getLogisticsStatus(): Array<{ label: string; value: string }> {
    const logisticsStatus: Array<{ label: string; value: string }> = [];
    for (const key in LogisticsStatusEnum) {
      if (isNaN(Number(key))) {
        logisticsStatus.push({
          label: this.$t(`logisticsStatus.${key}`).toString(),
          value: LogisticsStatusEnum[key]
        });
      }
    }
    return logisticsStatus;
  }
  private getLogisticsOrderStatus(): Array<{ label: string; value: string }> {
    const logisticsOrderStatus: Array<{ label: string; value: string }> = [];
    for (const key in LogisticsOrderStatusEnum) {
      if (isNaN(Number(key))) {
        logisticsOrderStatus.push({
          label: this.$t(`logisticsOrderStatus.${key}`).toString(),
          value: LogisticsOrderStatusEnum[key]
        });
      }
    }
    return logisticsOrderStatus;
  }

  private async export(): Promise<void> {
    this.tableOption.loading = true;
    try {
      const exportQuery = {
        idList: this.selectedRows.map(x => x.id),
        ...this.queryForm
      };
      const blob = await logisticsService.export(exportQuery);
      downloadFileByBlob(`${translation('logistics.exportFileName')}_${dateFormat(new Date())}.xlsx`, blob);
      this.clearSelection();
    } catch (error) {
      messageError(error);
    } finally {
      this.tableOption.loading = false;
    }
  }

  private clearSelection(): void {
    (this.$refs.logisticsTable as OsTable).clearSelection();
    this.selectedRows = [];
  }

  private async getExpressCompanys(): Promise<void> {
    try {
      const companys = (await logisticsService.getExpressCompanys()).map(x => {
        return {
          label: x.name,
          value: x.code
        };
      });
      const companyQuery = this.queryItemsOption.find(x => x.field === 'expressCom');
      if (companyQuery) {
        companyQuery.optionData = companys;
      }
    } catch (error) {
      messageError(error);
    }
  }
}
